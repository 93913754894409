import React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import Container from "../components/layout/Container"
import Header from "../components/Header"
import Space from "../components/Space"
import Content from "../components/layout/Content"
import Pagination from "../components/Pagination"
import FaqItem from "../components/FaqItem"
import TrainingBanner from "../components/TrainingBanner"
import { Helmet } from "react-helmet"
import { SEO_DESCRIPTION, SEO_KEYWORDS } from "../utils"

const Title = styled.h1`
    text-align: center;
    margin-bottom: 4rem;
`

export default props => {
    const { edges } = props.data.allMarkdownRemark
    const { pageContext } = props
    return (
        <Container>
            <Helmet>
                <title>О косах и прическах</title>
                <meta name="keywords" content={SEO_KEYWORDS} />
                <meta name="description" content={SEO_DESCRIPTION} />
            </Helmet>
            <Header />
            <TrainingBanner />
            <Space />
            <Content>
                <Title>О плетении косичек и причёсках</Title>
                {edges.map((item, i) => (
                    <FaqItem
                        key={`arts_${i}`}
                        title={
                            item.node.frontmatter.title
                                ? item.node.frontmatter.title
                                : "Нет заголовка"
                        }
                        date={
                            item.node.frontmatter.date
                                ? item.node.frontmatter.date
                                : ""
                        }
                        slug={
                            item.node.frontmatter.slug
                                ? item.node.frontmatter.slug
                                : ""
                        }
                    />
                ))}
            </Content>
            <Pagination
                currentPage={pageContext.currentPage}
                totalItems={pageContext.totalItems}
                display={pageContext.limit}
                urlFirst="/main/page/all_about_braids"
                url="/main/index/page/"
            />
            <Space />
        </Container>
    )
}

export const articleListQuery = graphql`
    query articlesListQuery($skip: Int!, $limit: Int!) {
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            limit: $limit
            skip: $skip
            filter: { frontmatter: { type: { eq: "article" } } }
        ) {
            edges {
                node {
                    frontmatter {
                        slug
                        title
                        date
                    }
                }
            }
        }
    }
`
